<template>
  <div id="app">
  
    <NavBar/>
    <BreadCumber/>
 
    <router-view></router-view>

  </div>
</template>

<script>
import NavBar from './elements/Navbar';
import BreadCumber from './elements/Breadcumber';

export default {
  name: 'App',
  components: {
     NavBar, BreadCumber
  }
}
</script>

<style>



#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
