<template> 

  <div>

    <!-- <div class="control-section">
      <div class="col-lg-12 e-img-editor-sample">
        <ejs-imageeditor :theme="themeValue" id="image-editor" ref="imageEditorObj" :created = "created">

        </ejs-imageeditor>
      </div>
    </div> -->


    <Loader text="Ładowanie generatora" v-if="!user.id" />
  
    <div class="container pt-4" v-if="user.id">

      <div class="pt-3">

        <label class="text-muted">Co chcesz dziś wygenerować?</label>

        <b-input-group>

          <b-form-input v-model="query" size="lg" ref="query" placeholder="np. czerwony samochód"></b-form-input>

        </b-input-group>


        <div class="card mt-4">

          <div class="card-body">
            <span class="card-title-inline">

              <span class="material-symbols-outlined align-middle" style="font-weight: 100;transform: rotate(270deg);font-size: 18px;">
                arrow_top_left
              </span>

              Tryb

            </span>

            <div class="form-check mt-1">
              <input class="form-check-input" type="radio" v-model="typeOfPicture" value="realistic" id="ch1" style="margin-top:6px;">
              <label class="form-check-label" for="ch1">
                <small>Realizm</small>
              </label>
            </div>

            <div class="form-check">
              <input class="form-check-input" type="radio" v-model="typeOfPicture" value="cute" id="ch2" style="margin-top:6px;">
              <label class="form-check-label" for="ch2">
                <small>Rysunek / szkic</small>
              </label>
            </div>

            <div class="form-check">
              <input class="form-check-input" type="radio" v-model="typeOfPicture" value="scenic" id="ch3" style="margin-top:6px;">
              <label class="form-check-label" for="ch3">
                <small>Grafika</small>
              </label>
            </div>

          </div>

        </div>


        <b-button variant="outline-violet mr-1" ref="generateBtn" @click="generate()" class="mt-3 float-right btn-sm" v-if="!task_id">
          <span class="material-symbols-outlined align-middle">
            brush
          </span>
          Generuj
        </b-button>

        <b-button variant="outline-gray mr-1" ref="cancelBtn" @click="cancel()" class="mt-3 float-right btn-sm" v-if="task_id">
          <span class="material-symbols-outlined align-middle">
            close
          </span>
          Anuluj
        </b-button>

        <div class="clearfix"></div>

        <div class="card mt-2" v-if="progress > 0">

          <div class="card-body">

            <span class="card-title-inline">

              <span class="material-symbols-outlined align-middle" style="font-weight: 100;transform: rotate(270deg);font-size: 18px;">
                arrow_top_left
              </span>

              Trwa generowanie ( <span class="text-violet-light">{{ progress }}%</span> )</span>

              <div class="progress mt-2">
                <div class="progress-bar progress-bar-striped bg-purple progress-bar-animated" role="progressbar" :style="{ width: progress + '%' }"></div>
              </div>

          </div>

        </div>




        <div class="card mt-3" v-if="files.length > 0">

          <div class="card-body">

            <span class="card-title-inline">

              <span class="material-symbols-outlined align-middle" style="font-weight: 100;transform: rotate(270deg);font-size: 18px;">
                arrow_top_left
              </span>

              Wyniki

            </span>

            <div class="row align-items-center justify-content-center">

              <div v-for="file in files" v-bind:key="file">

                <div class="col text-center">
                  <img :src="file" style="max-width: 230px;height: auto;border-radius: 10px;"/>
                </div>

              </div>

            </div>

          </div>

        </div>







        <BR/>
        <BR/>
        <BR/>
        <BR/>
        <BR/>
        <BR/>
        <BR/>
        <BR/>
        <BR/>
        <BR/>

      </div>

    </div>

  </div>

</template>

<script>

  import Loader from '../../elements/Loader';
  //import { ImageEditorComponent } from "@syncfusion/ej2-vue-image-editor";
  //import { Browser, getComponent, isNullOrUndefined } from "@syncfusion/ej2-base";

  export default {

    name: 'Generator',

    components: {
      Loader,
      //'ejs-imageeditor': ImageEditorComponent
    },

    data() {

      return {
        user: {
          id: null,
          permissions: []
        },
        query: '',
        task_id: null,
        progress: 0,
        typeOfPicture: 'realistic',
        log: '',
        alert: '',
        files: []
      }

    },

    onScroll: function() {
     /* if (document.getElementById('imageeditor_sliderWrapper')) {
        var slider = getComponent(document.getElementById('imageeditor_sliderWrapper'), 'slider');
        slider.refreshTooltip(slider.tooltipTarget);
      }*/
    },
    mounted: function() {
  /*if (!isNullOrUndefined(document.getElementById("right-pane"))) {
    document.getElementById("right-pane").addEventListener("scroll", this.onScroll.bind(this));
  }*/
},
computed: {
  themeValue: {
    get: function () {
      return this.theme;
    },
    set: function (theme) {
      this.theme = theme
    }
  }
},
created() {


  this.axios.get(window.API_URL + "user", { withCredentials: true }).then((response) => {

    let data = response.data

    if(data.redirectTo) {

      top.location.href = data.redirectTo;

    }

    if(data.userId) {

      this.user.id = data.userId
      this.user.permissions = data.permissions

    }

  });

  // Set breadcumbs
  this.$root.$emit('system.breadcumber.set', [])

},

methods: {

  created: function() {

  /*if (Browser.isDevice) {
    this.$refs.imageEditorObj.open('http://192.168.88.29/akademiagrafiki.ai/api/images/serve/b2a1b535cec35f7183ea9a7116fffd02');
  } else {
    this.$refs.imageEditorObj.open('http://192.168.88.29/akademiagrafiki.ai/api/images/serve/b2a1b535cec35f7183ea9a7116fffd02');
  }
  if (this.themeValue && window.location.href.split('#')[1]) {
    this.themeValue = window.location.href.split('#')[1].split('/')[1];
  }*/

},
cancel: function () {

  this.task_id = null
  this.progress = 0

},

check: function () {

  if(this.task_id) {

    this.axios.post(window.API_URL + "images/check", { 'task_id': this.task_id }, { withCredentials: true, timeout: 3000000 }).then((response) => {

        if(this.task_id) {

          let data = response.data

          if (data.redirectTo) {

            top.location.href = data.redirectTo;

          }

          this.log = data

          if (data.files) {

            this.files = data.files

            this.cancel()

          }

          if (data.progress) {

            this.progress = data.progress

          }

        }

    }).catch(function() {

      //this.$refs.generateBtn.disabled = false

    })


  }

},

generate: function () {

  //this.$refs.generateBtn.disabled = true

  this.alert = ''
  this.files = []

  let query = this.query + ". "

  if(this.typeOfPicture === 'realistic') {

    query = query + "Realistic photo. --v 5.2 --style raw"

  }

  if(this.typeOfPicture === 'cute') {

    query = query + "--niji 5 --style cute"

  }

  if(this.typeOfPicture === 'scenic') {

    query = query + "--niji 5 --style scenic"

  }

  this.log = query

  this.axios.post(window.API_URL + "images/generate", { 'prompt': query }, { withCredentials: true, timeout: 3000000 }).then((response) => {

    this.progress = 5
    let data = response.data

    if(data.redirectTo) {

      top.location.href = data.redirectTo;

    }

    if(data.success) {

      this.task_id = data.messageId

      setInterval(() => this.check(), 5000);

    } else {

      if(data.isNaughty) {

        this.alert = "Zakazane słowo"

      } else {

        console.log(data)

      }

    }

  }).catch(function() {

    //this.$refs.generateBtn.disabled = false

  })



}
}

}

</script>

<style scoped>



.e-img-editor-sample {
  height: 80vh;
  width: 100%;
}

@media only screen and (max-width: 700px) {
  .e-img-editor-sample {
    height: 75vh;
    width: 100%;
  }
}

.control-wrapper {
  height: 100%;
}

.e-image-editor {
  margin: 0 auto;
}
</style>